import { colorMD } from "@/design/colors/mdColors";
import { indigoGradient } from "@/design/gradients/gradientConst";

import { publicAccessMixin } from "@/mixins/public/publicAccessMixin";

/**
 * Toolbar Mixin
 */
export const toolbarMixin = {
  mixins: [publicAccessMixin],
  props: {
    iconName: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    dark: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: true
    },
    elevation: {
      type: Number,
      default: 5
    },
    toolbarStyle: {
      type: String,
      default: "margin: -30px 0 0;"
    }
  },
  computed: {
    toolbarColor: () => undefined,
    gradient() {
      return this.isPublicAccess
        ? this.publicAccess?.appBar?.gradient ?? undefined
        : this.toolbarColor?.name === colorMD.indigo
        ? indigoGradient
        : undefined;
    }
  }
};

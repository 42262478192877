<template>
  <v-toolbar
    :dark="dark"
    :rounded="rounded"
    :color="dataTableToolbarColorClass"
    :dense="dense"
    :elevation="elevation"
    :style="toolbarStyle"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:img="{ props }">
      <v-img v-if="gradient" v-bind="props" :gradient="gradient"></v-img>
    </template>

    <!-- Toolbar Icon -->
    <v-btn icon v-if="iconName">
      <v-icon>
        <slot name="icon-name">{{ iconName }}</slot>
      </v-icon>
    </v-btn>

    <!-- title -->
    <v-toolbar-title>
      <slot name="default">{{ title }}</slot>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <!-- Notifications -->
    <slot name="notifications"></slot>

    <!-- options -->
    <slot name="options"></slot>
  </v-toolbar>
</template>

<script>
import { dataTableToolbarSettingsMixin } from "@/mixins/shared/base/settings/dataTableToolbarSettingsMixin";
import { toolbarMixin } from "@/mixins/shared/base/toolbar/toolbarMixin";

export default {
  name: "TableToolbar",
  mixins: [toolbarMixin, dataTableToolbarSettingsMixin],
  inheritAttrs: false,
  computed: {
    /**
     * Overwrites toolbarMixin.toolbarColor
     * @returns {{name: String, variantType: String, variantNumber: Number}}
     */
    toolbarColor() {
      return this.dataTableToolbarColor;
    }
  }
};
</script>

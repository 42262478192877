// design
import { Color } from "@/design/colors/Color";

// vuex/store
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("solution");

// mixins
import { publicAccessMixin } from "@/mixins/public/publicAccessMixin";
import { isEmpty } from "@/utils";

/**
 * Data Table Toolbar Settings Mixin encapsulate Settings functionality of data Table Toolbar
 */
export const dataTableToolbarSettingsMixin = {
  mixins: [publicAccessMixin],
  computed: {
    ...mapGetters({
      dataTableToolbarColor: "dataTableToolbarColor"
    }),
    /**
     * Get Details Toolbar Material Design color
     * @returns {Color} Details Toolbar Material Design color
     */
    mdDataTableToolbarColor() {
      return new Color(
        this.dataTableToolbarColor.name,
        this.dataTableToolbarColor.variantType,
        this.dataTableToolbarColor.variantNumber
      );
    },
    /**
     * Data Table Toolbar color class
     * @returns {string|*} Data Table Toolbar color class
     */
    dataTableToolbarColorClass() {
      if (this.isPublicAccess) {
        const color = this.publicAccess?.appBar?.color;
        return isEmpty(color)
          ? this.mdDataTableToolbarColor.getClassColor()
          : color;
      }
      return this.mdDataTableToolbarColor.getClassColor();
    }
  }
};
